<template>
  <div class="course-select">
    <div class="empty" @click="onClose"></div>
    <div class="body">
      <div class="header-tool">
        <div class="label">选择课程</div>
      </div>
      <div class="main">
        <div class="course">
          <!-- <p
            style="font-size:16px; margin: 12px; height: 40px"
            v-for="item in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]"
            :key="item"
          >
            好长的一段话
          </p> -->
          <div class="item" v-for="(item, index) in courseList" :key="index" @click="onSelect(item)">
            <img :src="todoIcon" />
            <div class="course-name">{{ item.courseName }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  model: {
    prop: "selectCourse",
    event: "onSelectCourse",
  },
  props: {
    selectCourse: Object,
    courseList: Array,
  },
  data() {
    return {
      leftIcon: require("@/assets/images/user-learn-plan/arrow-left.png"),
      rightIcon: require("@/assets/images/user-learn-plan/arrow-right.png"),
      todoIcon: require("@/assets/images/user-learn-plan/todo.png"),
    };
  },
  computed: {},
  watch: {
    show(newValue) {
      console.log(newValue);
    },
  },
  mounted() {},
  methods: {
    render() {},
    nextMonth() {},
    onClose() {
      console.log("emit onCloseCourseSelector");
      this.$EventBus.$emit("onCloseCourseSelector", true);
    },
    onSelect(item) {
      this.$emit("onSelectCourse", item);
      this.onClose();
    },
  },
};
</script>

<style scoped lang="less">
.course-select {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 20;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  // justify-content: space-between;
  .empty {
    flex: 1;
  }
  .body {
    background: #ffffff;
    border-radius: 24px 24px 0px 0px;
    display: flex;
    flex-direction: column;
    .label {
      margin: 20px 54px;
      font-size: 18px;
      color: #242424;
      letter-spacing: 0;
      line-height: 30px;
      display: flex;
      flex-direction: row;
    }
    .main {
      margin: 0px 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      overflow-y: scroll;
      .course {
        height: 300px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        overflow-y: scroll;
        .item {
          padding: 14px 0px;
          width: 320px;
          display: flex;
          flex-direction: row;
          align-items: center;
          border-bottom: 1px solid rgb(242, 242, 242);
          img {
            height: 16px;
            width: 16px;
            margin: 0px 13px 0px 0px;
          }
          .course-name {
            font-size: 18px;
            color: #505051;
            letter-spacing: 0.4px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
